import revive_payload_client_qD7fmMhdw2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Dsqqp9Ydv2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_V8zknDPtXX from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Zid3JQzeH1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pla0i0iayW from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZYB9HsIL2G from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Alr4wwyiGF from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/platform-admin/.nuxt/components.plugin.mjs";
import prefetch_client_BsBIPwtT2K from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.13.2_@upstash+redis@1.34.4_@vercel+kv@3.0.0__vk6uhlyknm6d3u4x6y7i63gv3y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ywJDomcNte from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.2.11_rollup@4.22.5_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import tooltip_KTCF4H9yoS from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.3.1_magicast@0.2.11_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16._hobhujc2zqzx5k5pmh6y3jn4zq/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/platform-admin/plugins/bugsnag.ts";
export default [
  revive_payload_client_qD7fmMhdw2,
  unhead_Dsqqp9Ydv2,
  router_V8zknDPtXX,
  payload_client_Zid3JQzeH1,
  navigation_repaint_client_pla0i0iayW,
  check_outdated_build_client_ZYB9HsIL2G,
  chunk_reload_client_Alr4wwyiGF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BsBIPwtT2K,
  plugin_ywJDomcNte,
  tooltip_KTCF4H9yoS,
  bugsnag_tkMQfOjcVZ
]